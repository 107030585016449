import { applyMiddleware, createStore, compose } from 'redux'
import persistStore from 'redux-persist/es/persistStore'
import persistedReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = configureStore({ reducer: persistedReducer })

const persistor = persistStore(store)

export { store, persistor }