import { CameraInfo } from "../redux/types"

export const getLatestCameraReading = (cameras: CameraInfo[]): Date | undefined | null => {
    let timestamps: Date[] = []
    for (let i = 0; i < cameras.length; i++) {
        const camera = cameras[i]
        if (!camera.lastReadingDate) {
            continue
        }
        timestamps.push(camera.lastReadingDate)
    }
    if (timestamps.length < 1) {
        return null
    }
    let latest = Math.max(...(timestamps.map((timestamp) => (new Date(timestamp).getTime()))))
    return new Date(latest)
}
