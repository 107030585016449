import React, { memo } from 'react'
import { FarmSelects, FarmSelect } from '../redux/types'
import Select from 'react-select'
import { getPings } from '../redux/slices/status_slice'
import { connect } from 'react-redux'
import DataTable from 'react-data-table-component'

const pingTableColumns = [
  {
    name: 'Camera ID',
    selector: 'cameraId',
    sortable: true,
  },
  {
    name: 'Timestamp',
    selector: 'timestamp',
    sortable: true,
  },
]

interface Props {
  farmsAsSelect: FarmSelects
  getPings: Function
  pings: any
}

interface State {
  selectedFarm: FarmSelect
}

// function Pings(props: Props) {
class Pings extends React.Component<Props, State> {
  // console.log('pings farmsAsSelect == ', props.farmsAsSelect)
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedFarm: { value: '', label: '' },
    }
  }

  setSelectedFarm = (selectedOption: FarmSelect) => {
    this.props.getPings(selectedOption.value)
    this.setState({ selectedFarm: selectedOption })
  }

  render() {
    const { selectedFarm } = this.state
    return (<React.Fragment>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', width: '50%' }}>
        <Select
          options={this.props.farmsAsSelect as any}
          // $FlowFixMe
          value={selectedFarm}
          onChange={(val: any) => {
            this.setSelectedFarm(val)
          }}
        />
      </div>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', width: '50%' }}>
        <DataTable
          title="Pings"
          columns={pingTableColumns}
          data={this.props.pings}
          pagination
        />
      </div>
    </React.Fragment>)
  }
}

const mapStateToProps = (state: any) => ({
  farmData: state.farmDataReducer,
  configData: state.configDataReducer,
  pings: state.statusReducer.pings
})
const mapDispatchToProps = (dispatch: Function) => {
  return {
    getPings: (farmId: string, dateFrom: Date, dateTo: Date) =>
      dispatch(getPings(farmId, dateFrom, dateTo)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Pings))