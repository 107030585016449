import React, { useState } from "react"
import moment from "moment"
import { Note } from "../redux/types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"

interface Props {
    notes: Note[] | undefined
    saveNote: (text: string) => void
    deleteNote: (noteId: string) => void
}

const NOTE_COUNT = 1

const FarmNotes = (props: Props) => {
    const { notes, saveNote, deleteNote } = props

    const [text, setText] = useState('')
    const [showAll, setShowAll] = useState(false)

    const saveButtonClicked = () => {
        saveNote(text)
        setText('')
    }

    const lastNotesOrdered = (notes: Note[]) => {
        let n = notes.slice(0, notes.length) // clone array
        n.sort((a, b) => ((a.timestamp > b.timestamp) ? 1 : -1))
        if (!showAll) {
            return n.slice(notes.length - NOTE_COUNT, notes.length)
        }
        return n
    }

    const deleteButtonClicked = (noteId: string) => {
        if (confirm('Are you sure you want to delete this note?')) {
            deleteNote(noteId)
        }
    }

    return (
        <>
            <div className="notes">
                <div className="notes-title">
                    <h3>Notes</h3>
                    {notes && notes.length > NOTE_COUNT &&
                        <span onClick={() => setShowAll(!showAll)}>{showAll ? 'Show less' : 'Show all (' + notes.length + ')'}</span>
                    }
                </div>
                {notes && notes.length === 0 &&
                    <div className="notes-empty">
                        <span>no notes to display</span>
                    </div>
                }
                <div className="notes-list">
                    {notes && lastNotesOrdered(notes).map((note: Note, index) => (
                        <div className="note" key={note._id}>
                            <div className="note-button delete-button">
                                <FontAwesomeIcon icon={faTimes} className='note-icon delete-icon' title='Delete note' onClick={() => deleteButtonClicked(note._id)} />
                            </div>
                            <div className="note-title">
                                Comment added by {note.username} on {moment.utc(note.timestamp).local().format('MMMM Do YYYY \\a\\t h:mma')}
                            </div>
                            <div className="note-text">
                                <q>{note.text}</q>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="notes-add">
                    <div className="note">
                        <div className="note-title">
                            Add a new comment
                        </div>
                        <div className="note-text">
                            <textarea placeholder='Add your note...' className='ignore-secret-input' onChange={(e) => setText(e.target.value)} value={text}></textarea>
                        </div>
                        <div className="note-button save-button">
                            <FontAwesomeIcon icon={faPlus} className='note-icon save-icon' title='Save note' onClick={saveButtonClicked} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FarmNotes