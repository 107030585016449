import * as React from 'react'
import { connect } from 'react-redux'

interface Props {
  pollingAction: Function
}

export const withPolling = (pollingAction: Function, duration = 2000) => (Component: React.Component) => {
  console.log('withPolling()')
  const Wrapper = () => (
    class extends React.Component<Props> {
      dataPolling: any
      componentDidMount() {
        this.props.pollingAction()

        this.dataPolling = setInterval(
          () => {
            this.props.pollingAction()
          },
          duration)
      }
      componentWillUnmount() {
        clearInterval(this.dataPolling)
      }
      render() {
        // es
        // @ts-ignore
        return (<Component {...this.props} />)
      }
    })
  const mapStateToProps = () => ({})
  const mapDispatchToProps = { pollingAction }
  // @ts-ignore
  return connect(mapStateToProps, mapDispatchToProps)(Wrapper())
}