import Fingerprint2 from "fingerprintjs2"
import axios from "axios"
import jwtDecode from "jwt-decode"

import { API_BASE_URL } from "./api"

export const REFRESH_TOKEN = "HV_REFRESH_TOKEN"
export const ACCESS_TOKEN = "HV_ACCESS_TOKEN"

// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const REFRESH_REQUEST = "REFRESH_REQUEST"
export const REFRESH_SUCCESS = "REFRESH_SUCCESS"
export const REFRESH_FAILURE = "REFRESH_FAILURE"
export const LOAD_TOKENS = "LOAD_TOKENS"

function requestRefresh(token: any) {
  return {
    type: REFRESH_REQUEST,
    payload: {
      token,
    },
  }
}

function receiveRefresh(user: any) {
  let decoded = jwtDecode(user.access.replace("Bearer ", ""))
  return {
    type: REFRESH_SUCCESS,
    payload: {
      decoded: decoded,
      access: user.access,
    },
  }
}

function refreshError(message: any) {
  return {
    type: REFRESH_FAILURE,
    payload: {
      message,
    },
  }
}

// eslint-disable-next-line
function requestLogin(creds: any) {
  return {
    type: LOGIN_REQUEST,
  }
}

function receiveLogin(user: any) {
  let decoded = jwtDecode(user.access)
  return {
    type: LOGIN_SUCCESS,
    payload: {
      decoded: decoded,
      refresh: user.refresh,
      access: user.access,
    },
  }
}

function loginError(message: any) {
  return {
    type: LOGIN_FAILURE,
    payload: {
      message,
    },
  }
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  }
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  }
}

// Logs the user out
export function logoutUser() {
  return (dispatch: any) => {
    dispatch(requestLogout())
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(ACCESS_TOKEN)
    dispatch(receiveLogout())
  }
}

function fingerprint() {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    new Fingerprint2().get((result: any) => {
      if (result) {
        return resolve(result)
      } else {
        return reject(new Error())
      }
    })
  })
}

export function loginUser(creds: any) {
  console.log('Trying to log user in')
  return (dispatch: any) => {
    return fingerprint()
      .then((result) => {
        // @ts-ignore
        dispatch(requestLogin())
        return axios.post(API_BASE_URL + "auth/authenticate", {
          username: creds.username,
          password: creds.password,
          deviceId: result,
        })
      })
      .then((response) => {
        if (response.status === 200) {
          let decoded: any = jwtDecode(response.data.access)
          if (['root', 'admin', 'observer', 'cs'].find((group) => decoded.groups.includes(group))) {
            localStorage.setItem(REFRESH_TOKEN, response.data.refresh)
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            dispatch(receiveLogin(response.data))
          } else {
            dispatch(loginError("You don't have access to this resource"))
          }
        } else {
          dispatch(loginError(""))
        }
      })
      .catch((err) => {
        console.error(err)
        if (err.response) {
          if (err.response.status === 400) {
            dispatch(loginError("Username and password are required"))
          } else if (err.response.status === 401) {
            dispatch(loginError("Bad username / password"))
          } else if (err.response.status === 403) {
            dispatch(
              loginError(
                "Forbidden. You do not have permission to access this resource."
              )
            )
          } else {
            dispatch(loginError("Unknown error. Try again later."))
          }
        } else {
          dispatch(loginError("Unknown error. Try again later."))
        }
      })
  }
}

export function refreshToken(token: any) {
  return (dispatch: any) => {
    // @ts-ignore
    new Fingerprint2().get((result: any) => {
      // We dispatch requestLogin to kickoff the call to the API
      dispatch(requestRefresh(token))
      return axios
        .post(
          API_BASE_URL + "auth/refresh",
          {
            deviceId: result,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status !== 200) {
            dispatch(refreshError(response.data.message))
          } else {
            localStorage.setItem(ACCESS_TOKEN, response.data.access)
            // Dispatch the success action
            dispatch(receiveRefresh(response.data))
          }
        })
        .catch((err) => {
          console.error(err)
          dispatch(logoutUser())
        })
    })
  }
}
