import { createSlice } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'
import api, { API_BASE_URL } from '../actions/api'
import { AppThunk } from '../types'

export interface ConfigState {
  showHidden: boolean
  compactMode: boolean
  darkMode: boolean
  favouriteFarms: string[]
}

const initialState: ConfigState = {
  showHidden: false,
  compactMode: false,
  darkMode: false,
  favouriteFarms: [],
}

const configSlice = createSlice({
  name: 'farmData',
  initialState: initialState,
  reducers: {
    setShowHidden(state, action) {
      console.log('action == ', action)
      state.showHidden = action.payload
    },
    setCompactMode(state, action) {
      state.compactMode = action.payload
    },
    setDarkMode(state, action) {
      state.darkMode = action.payload
    },
    setFavouriteFarms(state, action) {
      state.favouriteFarms = action.payload
    },
    addFavouriteFarm(state, action) {
      state.favouriteFarms.push(action.payload)
    },
    removeFavouriteFarm(state, action) {
      const newFarms = state.favouriteFarms.filter((farmId) => farmId !== action.payload)
      state.favouriteFarms = newFarms
    }
  }
})

export const {
  setShowHidden,
  setCompactMode,
  setDarkMode,
  addFavouriteFarm,
  removeFavouriteFarm,
  setFavouriteFarms
} = configSlice.actions
export default configSlice.reducer

export const getFavouriteFarms = (): AppThunk => async dispatch => {
  try {
    const response = await api.get(API_BASE_URL + 'monitor/getFavouriteFarms/')
    console.log('faves!', response.data.payload)
    dispatch(setFavouriteFarms(response.data.payload))
  } catch (err) {
    toastr.error('Error', `Error adding favourite farm: ${err.message}`)
  }
}

export const addFavouriteFarmToConfig = (farmId: string): AppThunk => async dispatch => {
  try {
    const response = await api.post(API_BASE_URL + 'monitor/addFavouriteFarm/' + farmId)
    dispatch(addFavouriteFarm(farmId))
  } catch (err) {
    toastr.error('Error', `Error adding favourite farm: ${err.message}`)
  }
}

export const removeFavouriteFarmToConfig = (farmId: string): AppThunk => async dispatch => {
  try {
    const response = await api.delete(API_BASE_URL + 'monitor/deleteFavouriteFarm/' + farmId)
    dispatch(removeFavouriteFarm(farmId))
  } catch (err) {
    toastr.error('Error', `Error removing favourite farm: ${err.message}`)
  }
}