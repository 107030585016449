import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

interface Props {
    colDir: string;
    handleClick: Function
}

const SortColIcon = ({ colDir, handleClick }: Props) => {
    return (
        <div onClick={() => { handleClick() }} title={colDir} className="sort-col-icon">
            <FontAwesomeIcon icon={colDir === '' ? faSort : (colDir === 'asc' ? faSortUp : faSortDown)} />
        </div>
    )
}

export default SortColIcon