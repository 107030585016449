// @ flow
import axios, { AxiosResponse, AxiosError } from 'axios'
import { ACCESS_TOKEN } from './authActions'

// export const API_BASE_URL = 'https://ag-api-test.bmce-networks.com'
// export const API_BASE_URL = 'http://localhost:30000'

/*

// Replaced logic here with the AGSENZE_API_HOST env var, set this in .env.dev

console.log('PATH IN API == ', path)
if (path === 'ag-test.bmce-networks.com') {
  apiBaseUrl = 'https://ag-api-test.bmce-networks.com'
} else if (path === 'ag-dev.bmce-networks.com') {
  apiBaseUrl = 'https://ag-api-dev.bmce-networks.com'
} else if (
  path === 'herd-vision.bmce-networks.com' ||
  path === 'herdvision.agsenze.com'
) {
  apiBaseUrl = 'https://herd-vision-api.bmce-networks.com'
} else if (path === 'dev.herd-vision.bmce-networks.com') {
  apiBaseUrl = 'http://dev.herd-vision-api.bmce-networks.com'
} else if (path === 'localhost') {
  // apiBaseUrl = 'https://ag-api-dev.bmce-networks.com'
  apiBaseUrl = 'http://localhost:30000'
} else {
  apiBaseUrl = 'https://ag-api.bmce-networks.com'
}
*/

// export const API_BASE_URL = 'https://api.herd.vision/api/v1'
export const API_BASE_URL = process.env.REACT_APP_HERD_VISION_API

// export const API_BASE_URL = process.env.AGSENZE_API_HOST
//   ? process.env.AGSENZE_API_HOST
//   : ''

// export const AUTH_BASE_URL = process.env.AUTH_API_HOST
//   ? process.env.AUTH_API_HOST
//   : ''

console.log('setting API_BASE_URL to ', API_BASE_URL)

// var api = axios.create({
//   baseURL: '/api/v1'
// })

let api = axios.create()

api.interceptors.request.use(
  function (config) {
    config['headers'] = {
      ...config['headers'],
      Authorization: localStorage.getItem(ACCESS_TOKEN)
    }
    // console.log('We have some headers from config == ', config)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use((response: AxiosResponse) => {
  return response
},
  (error: AxiosError) => {
    console.log('error == ', error)
    if (error.response?.status === 401) {
      // redirect here
      console.log('trying to redirect')
      localStorage.clear()
      window.location.reload(true)
    }

  })

export default api
