import React from "react"
import moment from "moment"
import { CameraInfo } from "../redux/types"
import { HDD_ERROR_THRESHOLD } from "./live_farms_overview"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons"

interface Props {
    camera: CameraInfo
    ipLoading: boolean
    farmId: string
    submitIp: (farmId: string, cameraId: string, ip: string) => void
    deleteCamera: (farmId: string, cameraId: string) => void
}

const CameraRow = (props: Props) => {
    const { camera, ipLoading, farmId, submitIp, deleteCamera } = props
    const [ip, setIp] = React.useState(camera.ip)

    const deleteCameraClicked = (farmId: string, cameraId: string) => {
        if (confirm('Are you sure you want to remove this camera?')) {
            deleteCamera(farmId, cameraId)
        }
    }

    const submitIpClicked = (farmId: string, cameraId: string, ip: string) => {
        if (!ipLoading) {
            submitIp(farmId, cameraId, ip)
        }
    }

    return (
        <tr style={camera.diskPercent && camera.diskPercent > HDD_ERROR_THRESHOLD ? { backgroundColor: 'red' } : {}} key={farmId} >
            <td>
                <span className="delete-camera-button" onClick={() => deleteCameraClicked(farmId, camera.cameraId)} title="Delete camera">
                    <FontAwesomeIcon icon={faTrash} />
                </span>
            </td>
            <td style={{ textAlign: 'center' }}>{camera.cameraId}</td>
            <td>
                <input
                    name="test"
                    value={ip}
                    onChange={(e) => setIp(e.target.value)}
                    disabled={ipLoading}
                    style={{ cursor: ipLoading ? 'wait' : '' }}
                />
                <span
                    className="submit-cam-ip-button"
                    onClick={() => submitIpClicked(farmId, camera.cameraId, ip ?? '')}
                    style={{ cursor: ipLoading ? 'wait' : '', backgroundColor: ipLoading ? '#4caf4f4d' : '#4CAF50' }}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </span>
            </td>
            <td style={{ textAlign: 'center' }}>{camera.diskPercent}%</td>
            <td style={{ textAlign: 'center' }}>{camera.coretemp}*C</td>
            <td style={{ textAlign: 'center' }}>{camera.cpuPercent}%</td>
            <td style={{ textAlign: 'center' }}>{camera.ramPercent}%</td>
            <td style={{ textAlign: 'center' }}>{camera.pingDelay}ms</td>
            <td style={{ textAlign: 'center' }}>{camera.prodigyVersion}</td>
            <td style={{ textAlign: 'center' }}>{camera.socpVersion}</td>
            <td style={{ textAlign: 'center' }}>
                {camera.lastPing && camera.lastPing ? moment.utc(camera.lastPing).local().format('DD/MM/YY') : ''}
                <br />
                {camera.lastPing && camera.lastPing ? moment.utc(camera.lastPing).local().format('HH:mm:ss') : ''}
            </td>
            <td style={{ textAlign: 'center' }}>
                {camera.lastReadingDate ? moment.utc(camera.lastReadingDate).local().format('DD/MM/YY') : ''}
                <br />
                {camera.lastReadingDate ? moment.utc(camera.lastReadingDate).local().format('HH:mm:ss') : ''}
            </td>
            <td> {camera.lastEIDRecieve ? camera.lastEIDRecieve : ''}</td>
            <td> {camera.EID24hrCount ? camera.EID24hrCount : ''}</td>
            <td>{camera.DepthNoise ? camera.DepthNoise + '%' : ''}</td>
            <td>
                <a href={"http://" + camera.ip + ":8080"} target="_blank">Connect...</a>
            </td>
        </tr>
    )
}

export default CameraRow