import moment from "moment"

const ONE_DAY = 86400000
const ONE_HOUR = 3600000

interface ListData {
  _id?: string
  timestamp: Date
  connected: boolean
}

export interface UptimeGraphPart {
  timestamp: number
  uptime: number
  color: string
  isZero: number
}

const uptimeCalculator = (listData: ListData[], dateFrom: Date, dateTo: Date, groupBy: 'day' | 'hour') => {
  if (areDatesValid(dateFrom, dateTo, groupBy) || listData.length === 0) {
    return
  }

  let timeFrom = moment.utc(dateFrom).startOf(groupBy).valueOf()
  let timeTo = moment.utc(dateTo).startOf(groupBy).valueOf()
  
  let uptimeArray: UptimeGraphPart[] = []
  let datesForRange: Date[] = []
  let lastConnected: boolean
  let timeframe: number

  switch (groupBy) {
    case 'day':
      timeframe = ONE_DAY
      break
    case 'hour':
      timeframe = ONE_HOUR
  }

  // Grab all the readings before our date range
  let beforeSearch = listData
    .filter((d) => new Date(d.timestamp) < dateFrom)
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())

  // Grab all the readings inside our date range
  let betweenSearch = listData
    .filter((d) => new Date(d.timestamp) >= dateFrom && new Date(d.timestamp) <= dateTo)
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())

  if (beforeSearch[0]) { // are there any records before the date from to show if we were connected
    lastConnected = beforeSearch[0].connected
  } else if (betweenSearch[0]) { // if not then we assume its opposite to the first reading in our range
    lastConnected = betweenSearch[0].connected
  }

  // Build an array with all the dates that are between our range
  while (timeFrom <= timeTo) {
    datesForRange.push(new Date(timeFrom))
    timeFrom += timeframe
  }

  // Begin looping each date in our range
  datesForRange.forEach((date) => {
    let startOfDate = date.getTime()
    let endOfDate = date.getTime() + timeframe - 1

    // Grab only the data from our dates selected
    let dataForDate = listData.filter((d) => {
      let time = new Date(d.timestamp).getTime()
      if (startOfDate < time && endOfDate > time) {
        return true
      }
    })

    let downTime = 0

    // If we have no readings for this timeframe
    if (dataForDate.length === 0) {
      // If the last connected was false we know this entire timeframe is false
      if (!lastConnected) {
        downTime = timeframe
      }
    }

    // We want to compare 
    for (let i = 0; i < dataForDate.length; i++) {
      let current = dataForDate[i]
      let next = dataForDate[i + 1]

      // Last reading of the day/hour
      if (!next) {
        // If this last reading was disconnected we know we wont have another reading for the rest of the hour or day
        // So we say that the entire rest of the hour/day was disconnected
        if (!current.connected) {
          downTime += endOfDate - new Date(current.timestamp).getTime()
        }
        // Set last connected to the current, incase there are more dates to come
        lastConnected = current.connected
        continue
      }
      
      // If the current reading is disconnected then we can start to increment the down time between now and the next reading
      if (!current.connected) {
        downTime += new Date(next.timestamp).getTime() - new Date(current.timestamp).getTime()
        lastConnected = false
      }
      // If the last was not connected and now we are 
      if (!lastConnected && current.connected) {
        lastConnected = true
      }
    }

    let percentageUp = 100 - Math.round((downTime / timeframe) * 100)
    let color = percentageUp >= 60 ? '#2fe165' : percentageUp >= 40 ? '#de8a21' : '#e72318'
    // Nivo doesn't show zero values, so we set it to one and then change the tooltip to say 0
    uptimeArray.push({"timestamp": date.getTime(), "uptime": percentageUp < 1 ? 1 : percentageUp, "color": color, isZero: percentageUp < 1 ? 1 : 0})
  })

  return uptimeArray
}

export const areDatesValid = (dateFrom: Date, dateTo: Date, groupBy: 'day'|'hour'): string => {
  if (dateFrom > new Date() || dateTo > new Date()) {
    return 'Date from and date to must be before today'
  }
  if (dateFrom > dateTo) {
    return 'Date from must be before date to'
  }
  if (dateFrom < new Date('2024-01-01') || dateFrom < new Date('2024-01-01')) {
    return 'Date from and date to must not be earlier than January 1st 2024'
  }
  if (groupBy === 'hour' && moment(dateTo).diff(moment(dateFrom), 'days') > 3) {
    return 'Can not group more than 3 days by hour'
  }
  if (groupBy === 'day' && moment(dateTo).diff(moment(dateFrom), 'months') > 3) {
    return 'Can not group more than 3 months by day'
  }
  return ''
}

export default uptimeCalculator