import React from "react"
import { Farm, FarmEidTypes } from "../redux/types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

interface Props {
  farm: Farm
  farmEidTypes: FarmEidTypes
  ipLoading: boolean
  trimLenLoading: boolean
  setFarmEidType: (type: string) => void
  submitIp: (farmId: string, ip: string) => void
  submitTrimLen: (farmId: string, trimLen: number) => void
}

const MoreFarmInfo = (props: Props) => {
  const {
    farm,
    farmEidTypes,
    ipLoading,
    trimLenLoading,
    setFarmEidType,
    submitIp,
    submitTrimLen,
  } = props
  const [ip, setIp] = React.useState(farm.ip)
  const [trimLen, setTrimLen] = React.useState(farm.cowIdTrimLength)

  const submitIpClicked = (farmId: string, ip: string) => {
    if (!ipLoading) {
      submitIp(farm._id, ip)
    }
  }

  const submitTrimLenClicked = (farmId: string, trimLen: number) => {
    if (!trimLenLoading) {
      submitTrimLen(farmId, trimLen)
    }
  }

  return (
    <tr>
      <td>{farm._id}</td>
      <td>
        <input
          name="test"
          value={ip}
          onChange={(e) => setIp(e.target.value)}
          disabled={ipLoading}
          style={{ cursor: ipLoading ? "wait" : "" }}
        />
        <span
          className="submit-cam-ip-button"
          onClick={() => submitIpClicked(farm._id, ip ?? "")}
          style={{
            cursor: ipLoading ? "wait" : "",
            backgroundColor: ipLoading ? "#4caf4f4d" : "#4CAF50",
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </td>
      <td>
        <select onChange={(e) => setFarmEidType(e.target.value)}>
          {farmEidTypes &&
            farmEidTypes.selectOptions.map((eidType: any) => {
              return (
                <option
                  key={eidType.value}
                  value={farm._id + "|" + eidType.value}
                  selected={farm.eidType === eidType.value}
                >
                  {eidType.label}
                </option>
              )
            })}
          {!farmEidTypes && <option>Loading....</option>}
        </select>
      </td>
      <td>
        <input
          type="number"
          name="trimLen"
          value={trimLen}
          onChange={(e) => setTrimLen(parseInt(e.currentTarget.value))}
        />
        <span
          className="submit-cam-ip-button"
          onClick={() => submitTrimLenClicked(farm._id, trimLen ?? 0)}
          style={{
            cursor: trimLenLoading ? "wait" : "",
            backgroundColor: trimLenLoading ? "#4caf4f4d" : "#4CAF50",
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </td>
      <td>{farm.leftEdge}</td>
    </tr>
  )
}

export default MoreFarmInfo