import * as React from 'react'
import { connect } from 'react-redux'
// import { loadAgsenzeData } from "../redux/actions"
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { loginUser, logoutUser, REFRESH_TOKEN, refreshToken } from '../redux/actions/authActions'
import { addFavouriteFarmToConfig, ConfigState, getFavouriteFarms, removeFavouriteFarmToConfig, setCompactMode, setDarkMode, setShowHidden } from '../redux/slices/config_slice'
import {
  addNote,
  deleteCamera,
  deleteNote,
  fetchFarmData,
  fetchFarmEidTypes,
  fetchFarmIssues,
  fetchFarmsAsSelect,
  fetchFarmStatuses,
  setFarmEidType,
  setFarmIssues,
  setFarmStatus,
  updateCameraIp,
  updateFarmEidType,
  updateFarmIp,
  updateFarmIssues,
  updateFarmMobilityThresholdOne,
  updateFarmMobilityThresholds,
  updateFarmMobilityThresholdThree,
  updateFarmMobilityThresholdTwo,
  updateFarmStatus,
  updateTrimLen,
} from '../redux/slices/farm_data_slice'
import { getHdd } from '../redux/slices/status_slice'
import { FarmIssue, MobilityThresholds } from '../redux/types'
import FarmsLastImport from './farms_last_import'
import InstallsOverview from './installs_overview'
import IpList from './ip_list'
import LiveFarmsOverview from './live_farms_overview'
import PingLogs from './ping_logs_component'
import Pings from './pings_component'
import { withPolling } from "./with_polling"

const TOGGLE_SHOW_HIDDEN = 83
const CUSTOMER_SUCCESS_GROUP = "cs"

interface Props {
  farmData?: any
  configData?: ConfigState
  text: string
  deleteCamera: Function
  fetchFarmStatuses: Function
  fetchFarmIssues: Function
  fetchFarmEidTypes: Function
  fetchFarmsAsSelect: Function
  setFarmEidType: Function
  setFarmStatus: Function
  setFarmIssues: Function
  updateFarmIssues: Function
  updateFarmStatus: Function
  setShowHidden: Function
  setCompactMode: Function
  setDarkMode: Function
  updateFarmIp: Function
  updateFarmMobilityThresholds: Function
  updateFarmMobilityThresholdOne: Function
  updateFarmMobilityThresholdTwo: Function
  updateFarmMobilityThresholdThree: Function
  updateTrimLen: Function
  updateCameraIp: Function
  updateFarmEidType: Function
  addToFavourites: Function
  removeFromFavourites: Function
  getFavouriteFarms: Function
  status: any
  login: any
  addNote: Function
  deleteNote: Function
  isCustomerSuccess: boolean
  logout: Function
}

class DashboardComponent extends React.Component<Props> {
  componentDidMount() {
    console.log("Dashboard Component Mounted")
    document.addEventListener("keydown", this._handleKeyDown)
    this.props.fetchFarmStatuses()
    this.props.fetchFarmIssues()
    this.props.fetchFarmEidTypes()
    this.props.fetchFarmsAsSelect()
    this.props.getFavouriteFarms()
  }

  _handleKeyDown = (event: any) => {
    switch (event.keyCode) {
      case TOGGLE_SHOW_HIDDEN:
        // this.setState({ showActualValues: !this.state.showActualValues }
        // Stops the hidden columns toggling if the user types 's' into the text box
        if (
          !document.activeElement?.className.includes("ignore-secret-input")
        ) {
          this.props.setShowHidden(!this.props.configData?.showHidden)
        }
        break
      default:
        break
    }
  }

  updateFarmStatus = (value: string) => {
    const splitStr = value.split("|")
    const farmId = splitStr[0]
    const status = splitStr[1]
    this.props.updateFarmStatus(farmId, status)
    this.props.setFarmStatus(value)
  }

  deleteCamera = (farmId: string, cameraId: string) => {
    this.props.deleteCamera(farmId, cameraId)
  }

  updateFarmIssues = (options: any, action: any) => {
    let issueValues: Array<string> = []
    if (options) {
      issueValues = options.map((option: FarmIssue) => {
        return option.value
      })
    }

    const farmId = action.name
    this.props.updateFarmIssues(farmId, issueValues)
    this.props.setFarmIssues(farmId, issueValues)
  }

  updateFarmEidType = (value: string) => {
    const splitStr = value.split("|")
    const farmId = splitStr[0]
    const eidType = splitStr[1]
    console.log(
      "Setting EID type for farm : ",
      farmId + ", eidType == ",
      eidType
    )
    this.props.updateFarmEidType(farmId, eidType)
    this.props.setFarmEidType(value)
  }

  submitFarmIp = (farmId: string, ip: string) => {
    console.log(ip)
    this.props.updateFarmIp(farmId, ip)
  }

  mobilityThresholdsKeyPressed = (
    farmId: string,
    mobilityThresholds: MobilityThresholds
  ) => {
    console.log("mobilityThresholds == ", mobilityThresholds)
    this.props.updateFarmMobilityThresholds(farmId, mobilityThresholds)
    fetchFarmData()
  }

  mobilityThresholdOneKeyPressed = (
    farmId: string,
    mobilityThresholdOne: number
  ) => {
    console.log("mobilityThresholdOne == ", mobilityThresholdOne)
    this.props.updateFarmMobilityThresholdOne(farmId, mobilityThresholdOne)
    fetchFarmData()
  }

  mobilityThresholdTwoKeyPressed = (
    farmId: string,
    mobilityThresholdTwo: number
  ) => {
    console.log("mobilityThresholdTwo == ", mobilityThresholdTwo)
    this.props.updateFarmMobilityThresholdTwo(farmId, mobilityThresholdTwo)
    fetchFarmData()
  }

  mobilityThresholdThreeKeyPressed = (
    farmId: string,
    mobilityThresholdOne: number
  ) => {
    console.log("mobilityThresholdThree == ", mobilityThresholdOne)
    this.props.updateFarmMobilityThresholdThree(farmId, mobilityThresholdOne)
    fetchFarmData()
  }

  trimLenKeyPressed = (farmId: string, trimLen: number) => {
    console.log("trimLen == ", trimLen)
    this.props.updateTrimLen(farmId, trimLen)
    fetchFarmData()
  }

  updateCameraIp = (cameraId: string, farmId: string, ip: string) => {
    this.props.updateCameraIp(cameraId, farmId, ip)
  }

  compactModeClicked = (event: any) => {
    this.props.setCompactMode(event.target.checked)
  }

  darkModeClicked = (event: any) => {
    this.props.setDarkMode(event.target.checked)
  }

  addNote = (farmId: string, text: string) => {
    this.props.addNote(farmId, text)
  }

  deleteNote = (farmId: string, noteId: string) => {
    this.props.deleteNote(farmId, noteId)
  }

  updateTrimLen = (farmId: string, trimLen: number) => {
    this.props.updateTrimLen(farmId, trimLen)
  }

  addToFavourites = (farmId: string) => {
    this.props.addToFavourites(farmId)
  }

  removeFromFavourites = (farmId: string) => {
    this.props.removeFromFavourites(farmId)
  }

  // HDD : Total: {total}, Available: {available}
  render() {
    if (this.props.farmData && this.props.status) {
      const { available, total } = this.props.status.hdd
      const freeSpace = (available / 1000000000).toFixed(2)
      let freeSpaceStyle = { color: this.props.configData?.darkMode ? 'lightgreen' : 'green' }
      if (parseInt(freeSpace) < 2) {
        freeSpaceStyle.color = "red"
      }
      return (
        <React.Fragment>
          <Tabs
            className={`${this.props.configData?.darkMode ? 'dark' : ''}`}
            style={{ backgroundColor: this.props.configData?.darkMode ? '#0f0f0fa4' : 'white' }}
            selectedTabClassName='selected-tab'
          >
            <TabList>
              {!this.props.isCustomerSuccess && (
                <>
                  <Tab>Live</Tab>
                  <Tab>Installs</Tab>
                  <Tab>Pings</Tab>
                </>
              )}
              <Tab>Customer Success</Tab>
              <Tab>Ping Logs</Tab>
              {!this.props.isCustomerSuccess &&
                this.props.farmData &&
                this.props.farmData.vpnData && <Tab>IP List</Tab>}
              <input
                style={{ marginLeft: "30px" }}
                onClick={(event) => this.compactModeClicked(event)}
                type="checkbox"
              />
              Enable Compact Mode
              <input
                style={{ marginLeft: '30px' }}
                onClick={(event) => this.darkModeClicked(event)}
                type='checkbox' />Dark Mode
              <span style={{ marginLeft: "30px" }}>
                HDD : Total: {(total / 1000000000).toFixed(2)} GB, Available:{" "}
                {<span style={freeSpaceStyle}>{freeSpace} GB</span>}
              </span>
              <span
                style={{ marginLeft: "30px", cursor: "pointer", textDecoration: 'underline' }}
                onClick={() => this.props.logout()}
              >
                Logout
              </span>
            </TabList>
            {!this.props.isCustomerSuccess && (
              <>
                <TabPanel>
                  <LiveFarmsOverview
                    deleteCamera={this.deleteCamera}
                    farmData={this.props.farmData.farms}
                    farmStatuses={this.props.farmData.farmStatuses}
                    farmEidTypes={this.props.farmData.farmEidTypes}
                    farmIssues={this.props.farmData.farmIssues}
                    setFarmStatus={this.updateFarmStatus}
                    configData={this.props.configData}
                    updateFarmIssues={this.updateFarmIssues}
                    submitFarmIp={this.submitFarmIp}
                    mobilityThresholdsKeyPressed={
                      this.mobilityThresholdsKeyPressed
                    }
                    mobilityThresholdOneKeyPressed={
                      this.mobilityThresholdOneKeyPressed
                    }
                    mobilityThresholdTwoKeyPressed={
                      this.mobilityThresholdTwoKeyPressed
                    }
                    mobilityThresholdThreeKeyPressed={
                      this.mobilityThresholdThreeKeyPressed
                    }
                    trimLenKeyPressed={this.trimLenKeyPressed}
                    setFarmEidType={this.updateFarmEidType}
                    addNote={this.addNote}
                    deleteNote={this.deleteNote}
                    submitCameraIp={this.updateCameraIp}
                    cameraIpLoading={this.props.farmData.cameraIpLoading}
                    farmIpLoading={this.props.farmData.farmIpLoading}
                    trimLenLoading={this.props.farmData.trimLenLoading}
                    submitTrimLen={this.updateTrimLen}
                    addToFavourites={this.addToFavourites}
                    removeFromFavourites={this.removeFromFavourites}
                  />
                </TabPanel>
                <TabPanel>
                  <InstallsOverview
                    farmData={this.props.farmData.farms}
                    farmStatuses={this.props.farmData.farmStatuses}
                    farmEidTypes={this.props.farmData.farmEidTypes}
                    farmIssues={this.props.farmData.farmIssues}
                    setFarmStatus={this.updateFarmStatus}
                    configData={this.props.configData}
                    updateFarmIssues={this.updateFarmIssues}
                    submitFarmIp={this.submitFarmIp}
                    setFarmEidType={this.updateFarmEidType}
                  />
                </TabPanel>
                <TabPanel>
                  <Pings farmsAsSelect={this.props.farmData?.farmsAsSelect} />
                </TabPanel>
              </>
            )}
            <TabPanel>
              <FarmsLastImport
                farmData={this.props.farmData.farms}
                addNote={this.addNote}
                deleteNote={this.deleteNote}
                configData={this.props.configData}
              />
            </TabPanel>
            <TabPanel>
              <PingLogs
                farmsAsSelect={this.props.farmData?.farmsAsSelect}
                farms={this.props.farmData.farms}
              />
            </TabPanel>
            {!this.props.isCustomerSuccess &&
              this.props.farmData &&
              this.props.farmData.vpnData && (
                <TabPanel>
                  <IpList
                    openVpnIpInfo={this.props.farmData.openVpnIpInfo}
                    vpnData={this.props.farmData.vpnData}
                    configData={this.props.configData}
                  />
                </TabPanel>
              )}
          </Tabs>
        </React.Fragment>
      )
    } else {
      return <div>Loading....</div>
    }
  }
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  const {
    isAuthenticated, isFetching, errorMessage, username, groups,
  } = auth
  const isCustomerSuccess = groups ? groups.includes(CUSTOMER_SUCCESS_GROUP) : false
  return {
    groups,
    username,
    isAuthenticated,
    isFetching,
    errorMessage,
    farmData: state.farmDataReducer,
    configData: state.configDataReducer,
    status: state.statusReducer,
    isCustomerSuccess
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    deleteCamera: (farmId: string, cameraId: string) => dispatch(deleteCamera(farmId, cameraId)),
    fetchFarmStatuses: () => dispatch(fetchFarmStatuses()),
    fetchFarmIssues: () => dispatch(fetchFarmIssues()),
    fetchFarmEidTypes: () => dispatch(fetchFarmEidTypes()),
    fetchFarmsAsSelect: () => dispatch(fetchFarmsAsSelect()),
    setFarmStatus: (value: string) => dispatch(setFarmStatus(value)),
    setFarmEidType: (value: string) => dispatch(setFarmEidType(value)),
    setFarmIssues: (farmId: string, issues: Array<string>) => dispatch(setFarmIssues({ farmId, issues })),
    updateFarmStatus: (farmId: string, status: string) => dispatch(updateFarmStatus(farmId, status)),
    updateFarmEid: (farmId: string, eidType: string) => dispatch(updateFarmEidType(farmId, eidType)),
    updateFarmIssues: (farmId: string, issues: Array<string>) => dispatch(updateFarmIssues(farmId, issues)),
    setShowHidden: (showHidden: boolean) => dispatch(setShowHidden(showHidden)),
    setCompactMode: (showHidden: boolean) => dispatch(setCompactMode(showHidden)),
    setDarkMode: (showHidden: boolean) => dispatch(setDarkMode(showHidden)),
    updateFarmIp: (farmId: string, ip: string) => dispatch(updateFarmIp(farmId, ip)),
    updateFarmMobilityThresholds: (farmId: string, mobilityThresholds: MobilityThresholds) => dispatch(updateFarmMobilityThresholds(farmId, mobilityThresholds)),
    updateFarmMobilityThresholdOne: (farmId: string, mobilityThresholdOne: number) => dispatch(updateFarmMobilityThresholdOne(farmId, mobilityThresholdOne)),
    updateFarmMobilityThresholdTwo: (farmId: string, mobilityThresholdTwo: number) => dispatch(updateFarmMobilityThresholdTwo(farmId, mobilityThresholdTwo)),
    updateFarmMobilityThresholdThree: (farmId: string, mobilityThresholdThree: number) => dispatch(updateFarmMobilityThresholdThree(farmId, mobilityThresholdThree)),
    updateTrimLen: (farmId: string, trimLen: number) => dispatch(updateTrimLen(farmId, trimLen)),
    updateCameraIp: (cameraId: string, farmId: string, ip: string) => dispatch(updateCameraIp(cameraId, farmId, ip)),
    updateFarmEidType: (farmId: string, eidType: string) => dispatch(updateFarmEidType(farmId, eidType)),
    addNote: (farmId: string, text: string) => dispatch(addNote(farmId, text)),
    deleteNote: (farmId: string, noteId: string) => dispatch(deleteNote(farmId, noteId)),
    addToFavourites: (farmId: string) => dispatch(addFavouriteFarmToConfig(farmId)),
    removeFromFavourites: (farmId: string) => dispatch(removeFavouriteFarmToConfig(farmId)),
    getFavouriteFarms: () => dispatch(getFavouriteFarms()),
    refreshToken: () => {
      let token = localStorage.getItem(REFRESH_TOKEN)
      if (token === null || token === '') {
        return
      }
      dispatch(refreshToken(token))
    },
    logout: () => dispatch(logoutUser()),
    login: (creds: any) => dispatch(loginUser(creds)),
  }
}
// @ts-ignore
export default withPolling(fetchFarmData, 30000)(withPolling(getHdd, 30000)(connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)))