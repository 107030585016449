import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { useEffect } from "react"
import { ConfigState } from "../redux/slices/config_slice"
import { FarmData } from "../redux/types"
import { getFlagByName } from "../utils/farmFlags"
import searchFarmData from "../utils/searchFarmData"
import { sortFarmDataByCol } from "../utils/sortFarmDataByCol"
import FarmNotes from "./farm_notes"
import SortColIcon from "./sort_col_icon"

interface Props {
  farmData: { [key: string]: FarmData }
  addNote: (farmId: string, text: string) => void
  deleteNote: (farmId: string, noteId: string) => void
  configData: ConfigState | undefined
}

const FarmsLastImport = (props: Props) => {
  const { farmData, addNote, deleteNote } = props

  const [col, setCol] = React.useState("")
  const [dir, setDir] = React.useState("")
  const [farms, setFarms] = React.useState<FarmData[]>()
  const [search, setSearch] = React.useState("")

  const sortData = () => {
    let sortedFarms: FarmData[] = []
    if (farmData) {
      Object.entries(farmData).map(([key, fd]: [any, FarmData]) => {
        if (fd.farm.flags && !getFlagByName('hideOnMonitor', fd.farm.flags)) {
          sortedFarms.push(Object.assign({}, fd, { id: fd.farm._id }))
        }
      })
      if (col && dir) {
        sortedFarms = sortFarmDataByCol(sortedFarms, col, dir)
      }
      if (search) {
        sortedFarms = searchFarmData(sortedFarms, search)
      }
      setFarms(sortedFarms)
    }
  }

  useEffect(() => {
    sortData()
  }, [farmData, search, col, dir])

  const handleColumnClick = (colName: string) => {
    let newSortDir: string
    // Loops direction when a column is clicked again: none -> asc -> desc
    switch (dir) {
      case "":
        newSortDir = "asc"
        break
      case "asc":
        newSortDir = "desc"
        break
      case "desc":
        newSortDir = ""
        break
      default:
        newSortDir = ""
        break
    }

    if (colName !== col) {
      newSortDir = "asc"
    }

    setCol(colName)
    setDir(newSortDir)
  }

  return (
    <>
      <div style={{ position: 'absolute', right: '0', top: '0', margin: '0px' }}>
        <input
          className="search-bar ignore-secret-input"
          type="search"
          placeholder="search farms..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <span
          style={{
            cursor: "pointer",
            position: "absolute",
            marginLeft: "-26px",
            marginTop: "4px",
          }}
          onClick={() => setSearch('')}
        >
          x
        </span>
      </div>
      <table id="farms" className={`${props.configData?.darkMode ? 'dark' : ''}`}>
        <thead>
          <tr>
            <th style={{ width: "2%" }}></th>
            <th style={{ width: "49%" }}>
              <div className="thead-title">Farm Name</div>
              <SortColIcon
                colDir={col === "name" ? dir : ""}
                handleClick={() => handleColumnClick("name")}
              />
            </th>
            <th style={{ width: "49" }}>
              <div className="thead-title">Last herd list import</div>
              <SortColIcon
                colDir={col === "lastImport" ? dir : ""}
                handleClick={() => handleColumnClick("lastImport")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {farms &&
            farms.map((fd: FarmData) => (
              <CsRow key={fd.farm._id} fd={fd} addNote={addNote} deleteNote={deleteNote} />
            ))}
        </tbody>
      </table>
    </>
  )
}

interface CsRowProps {
  fd: FarmData
  addNote: (farmId: string, text: string) => void
  deleteNote: (farmId: string, noteId: string) => void
}

const CsRow = (props: CsRowProps) => {
  const { fd, addNote, deleteNote } = props
  const [isRowExpanded, setIsRowExpanded] = React.useState(false)

  const rowClicked = () => {
    setIsRowExpanded(!isRowExpanded)
  }

  return (
    <>
      <tr>
        <td>
          <FontAwesomeIcon icon={isRowExpanded ? faMinus : faPlus} style={{ cursor: 'pointer' }} onClick={() => rowClicked()} />
        </td>
        <td>{fd.farm.name}</td>
        <td>
          {fd.lastImportDate
            ? moment
              .utc(fd.lastImportDate)
              .format("Do MMMM YYYY \\a\\t h:mm a")
            : "No import available"}
        </td>
      </tr>
      {isRowExpanded &&
        <tr>
          <td colSpan={3}>
            <FarmNotes
              notes={fd.farm.notes}
              saveNote={(text: string) =>
                addNote(fd.farm._id, text)
              }
              deleteNote={(noteId: string) =>
                deleteNote(fd.farm._id, noteId)
              }
            />
          </td>
        </tr>
      }
    </>
  )
}

export default FarmsLastImport
