import React, { memo } from 'react'
import { ApiConnectedHistory, FarmData, FarmSelects, FarmSelect, NetworkStatusHistory, VpnConnectedHistory } from '../redux/types'
import Select from "react-select"
import moment from 'moment'
import { fetchFarmNetworkStatusHistory } from '../redux/slices/farm_data_slice'
import { connect } from 'react-redux'
import UptimeGraph from "./uptime_graph"

interface Props {
  farmsAsSelect: FarmSelects
  farms: { [key: string]: FarmData }
  fetchFarmNetworkStatusHistory: (
    farmId: string,
    dateFrom: Date,
    dateTo: Date
  ) => void
  farmNetworkStatusHistory: NetworkStatusHistory
}

interface State {
  selectedFarm: FarmSelect
  dateFrom: Date
  dateTo: Date
}

class PingLogs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedFarm: { value: "", label: "" },
      dateFrom: moment().subtract(1, "month").toDate(),
      dateTo: moment().toDate(),
    }
  }

  setSelectedFarm = (selectedOption: FarmSelect) => {
    this.setState({ selectedFarm: selectedOption })
    console.log("Getting farm network status history")
    this.props.fetchFarmNetworkStatusHistory(
      selectedOption.value,
      this.state.dateFrom,
      this.state.dateTo
    )
  }

  dateFromChanged = (dateFrom: Date) => {
    this.setState({ dateFrom: dateFrom })
    this.props.fetchFarmNetworkStatusHistory(
      this.state.selectedFarm.value,
      dateFrom,
      this.state.dateTo
    )
  }

  dateToChanged = (dateTo: Date) => {
    this.setState({ dateTo: dateTo })
    this.props.fetchFarmNetworkStatusHistory(
      this.state.selectedFarm.value,
      this.state.dateFrom,
      dateTo
    )
  }

  render() {
    const { farms, farmsAsSelect, farmNetworkStatusHistory } = this.props
    const { selectedFarm } = this.state

    return (
      <React.Fragment>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            width: "50%",
          }}
        >
          <Select
            options={farmsAsSelect as any}
            // $FlowFixMe
            value={selectedFarm}
            onChange={(val: any) => {
              this.setSelectedFarm(val)
            }}
          />
        </div>
        <UptimeGraph
          vpnConnectedHistory={farmNetworkStatusHistory?.vpnConnectedHistory}
          apiConnectedHistory={farmNetworkStatusHistory?.apiConnectedHistory}
          dateFromChange={(dateFrom) => this.dateFromChanged(dateFrom)}
          dateToChange={(dateTo) => this.dateFromChanged(dateTo)}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  farmNetworkStatusHistory: state.farmDataReducer.farmNetworkStatusHistory,
})
const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchFarmNetworkStatusHistory: (farmId: string) =>
      dispatch(fetchFarmNetworkStatusHistory(farmId)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(PingLogs))