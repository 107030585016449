import { FarmData } from "../redux/types"

const searchFarmData = (fd: FarmData[], s: string): FarmData[] => {
  if (fd.length < 1 || !s) {
    return fd
  }

  console.warn('searching for ', s)
  const searched: FarmData[] = []

  fd.forEach((f) => {
    if (f.farm.name.toLowerCase().includes(s.toLowerCase())) {
      searched.push(f)
    }
  })

  return searched
}

export default searchFarmData