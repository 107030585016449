import React from "react"
// import logo from "../../../assets/logo.svg"
import { connect } from "react-redux"
import ReduxToastr from "react-redux-toastr"
import "react-redux-toastr/lib/css/react-redux-toastr.min.css"
import DashboardComponent from "../../components/dashboard_component"
import Login from "../../components/login_component"
import { loginUser, logoutUser, REFRESH_TOKEN, refreshToken } from "../../redux/actions/authActions"
import "./App.css"

interface Props {
  isAuthenticated: boolean
  login: Function
}

const App = (props: Props) => {
  // if(!this.PaymentResponse.isAuthenticated)
  console.log('app props == ', props)
  const { isAuthenticated, login } = props
  if (isAuthenticated) {
    return (
      <div>
        <DashboardComponent />
        <ReduxToastr
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          preventDuplicates={true}
          timeOut={4000}
        />
      </div>
    )
  } else {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Login
          display
          errorMessage={''}
          onLoginClick={login} />
        <ReduxToastr
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          preventDuplicates={true}
          timeOut={4000}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  const {
    isAuthenticated, isFetching, errorMessage, username, groups,
  } = auth
  return {
    groups,
    username,
    isAuthenticated,
    isFetching,
    errorMessage,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    refreshToken: () => {
      let token = localStorage.getItem(REFRESH_TOKEN)
      if (token === null || token === '') {
        return
      }
      dispatch(refreshToken(token))
    },
    logout: () => dispatch(logoutUser()),
    login: (creds: any) => dispatch(loginUser(creds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
