import { HDD_ERROR_THRESHOLD } from '../components/live_farms_overview'
import { FarmData } from '../redux/types'
import { getLatestCameraReading } from './lastCameraReading'

const VERSION_PAD = 10

export const sortFarmDataByCol = (fd: FarmData[], col: string, dir: string): FarmData[] => {
    if (fd.length < 1 || !(dir === 'asc' || dir === 'desc')) {
        return fd
    }

    if (['name'].includes(col)) {
        fd.sort((a, b) => (a.farm.name.localeCompare(b.farm.name)))
    }

    if (['cameras'].includes(col)) {
        fd.sort((a, b) => ((a.farm.cameras?.length ?? 0) - (b.farm.cameras?.length ?? 0)))
    }

    if (['cowCount', 'cowsUnmatched24Hr', 'beefTwentyFourHourCowCount', 'cowReadings24Hr'].includes(col)) {
        fd.sort((a, b) => ((a[col as keyof typeof a] as unknown as number) - (b[col as keyof typeof b] as unknown as number)))
    }

    if (['24hr'].includes(col)) {
        fd.sort((a, b) => ((a.cowsMatched24Hr - b.cowsMatched24Hr)))
    }

    if (['matchedPer'].includes(col)) {
        fd.sort((a, b) => {
            // prevent infinity and NaN values messing up the sort
            const aRatio = ((a.cowCount !== 0) ? a.cowsMatched24Hr / a.cowCount : 0)
            const bRatio = ((b.cowCount !== 0) ? b.cowsMatched24Hr / b.cowCount : 0)

            return aRatio - bRatio
        })
    }

    if (['vpnConnected'].includes(col)) {
        fd.sort((a, b) => ((a.farm.cameras?.find((cam) => !cam.networkStatus?.vpnConnected) ? 1 : 0) - (b.farm.cameras?.find((cam) => !cam.networkStatus?.vpnConnected) ? 1 : 0)))
    }

    if (['apiConnected'].includes(col)) {
        fd.sort((a, b) => ((a.farm.cameras?.find((cam) => !cam.networkStatus?.apiConnected) ? 1 : 0) - (b.farm.cameras?.find((cam) => !cam.networkStatus?.apiConnected) ? 1 : 0) ))
    }

    if (['lastPing'].includes(col)) {
        fd.sort((a, b) => ((a.lastPing?.timestamp ?? 0) > (b.lastPing?.timestamp ?? 0) ? 1 : -1))
    }

    if (['lastReading'].includes(col)) {
        fd.sort((a, b) => ((a.farm.cameras ? (getLatestCameraReading(a.farm.cameras) ?? 0) : 0) > (b.farm.cameras ? (getLatestCameraReading(b.farm.cameras) ?? 0) : 0) ? 1 : -1))
    }

    if (['activeAlarms'].includes(col)) {
        fd.sort((a, b) => (a.farm.activeAlarms - b.farm.activeAlarms))
    }

    if (['hdd'].includes(col)) {
        fd.sort((a, b) => {
            let aFull = -1
            let bFull = -1
            if (a.farm.cameras) {
                for (let i = 0; i < a.farm.cameras.length; i++) {
                    if (a.farm.cameras[i].diskPercent && a.farm.cameras[i].diskPercent! > HDD_ERROR_THRESHOLD) {
                        aFull = 1
                    }
                }
            }
            if (b.farm.cameras) {
                for (let i = 0; i < b.farm.cameras.length; i++) {
                    if (b.farm.cameras[i].diskPercent && b.farm.cameras[i].diskPercent! > HDD_ERROR_THRESHOLD) {
                        bFull = 1
                    }
                }
            }
            return aFull - bFull
        })
    }

    if (['prodVer'].includes(col)) {
        fd.sort((a, b) => {
            let aV = a.farm.prodigyVersion ? parseInt(a.farm.prodigyVersion.replace(/\D/g, '').padEnd(VERSION_PAD, '0')) : 0
            let bV = b.farm.prodigyVersion ? parseInt(b.farm.prodigyVersion.replace(/\D/g, '').padEnd(VERSION_PAD, '0')) : 0
            return aV - bV
        })
    }

    if (['cvVer'].includes(col)) {
        fd.sort((a, b) => {
            let aV = a.farm.socpVersion ? parseInt(a.farm.socpVersion.replace(/\D/g, '').padEnd(VERSION_PAD, '0')) : 0
            let bV = b.farm.socpVersion ? parseInt(b.farm.socpVersion.replace(/\D/g, '').padEnd(VERSION_PAD, '0')) : 0
            return aV - bV
        })
    }

    if (['lastImport'].includes(col)) {
        fd.sort((a, b) => (a.lastImportDate - b.lastImportDate))
    }

    if (dir === 'desc') {
        fd.reverse()
    }
    return fd
}
