import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, FarmData, FarmIssues, FarmStatuses, FarmIssue, FarmEidTypes, OpenVpnIpInfo, FarmSelects, Farm, MobilityThresholds, VpnData } from '../types'
import { toastr } from 'react-redux-toastr'
import api, { API_BASE_URL } from '../actions/api'
import mockLinuxVpnData from '../../../data/mockLinuxVpnData.json'
import mockOpenVpnFarmList from '../../../data/mockOpenVpnFarmIpList.json'
import moment from 'moment'
import JwtDecode from 'jwt-decode'
import { ACCESS_TOKEN } from '../actions/authActions'

interface FarmDataState {
  farms: { [key: string]: FarmData } | null
  farmStatuses: FarmStatuses | null
  farmIssues: FarmIssues | null
  farmEidTypes: FarmEidTypes | null
  openVpnIpInfo: OpenVpnIpInfo | null
  vpnData: VpnData | null
  farmsAsSelect: FarmSelects | null
  error: string | null
  farmNetworkStatusHistory: Array<any> | null
  cameraIpLoading: boolean
  farmIpLoading: boolean
  trimLenLoading: boolean
}

const initialState: FarmDataState = {
  farms: {},
  farmStatuses: null,
  farmEidTypes: null,
  farmIssues: null,
  openVpnIpInfo: null,
  vpnData: null,
  farmsAsSelect: null,
  error: null,
  farmNetworkStatusHistory: null,
  cameraIpLoading: false,
  farmIpLoading: false,
  trimLenLoading: false
}

const farmDataSlice = createSlice({
  name: 'farmData',
  initialState: initialState,
  reducers: {
    loadFarmDataSuccess(state, action) {
      // @ts-ignore
      // state.push({ farmData })
      state.farms = action.payload
      state.error = null
    },
    loadFarmDataFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    loadOpenVpnIpInfoSuccess(state, action) {
      state.openVpnIpInfo = action.payload
      state.error = null
    },
    loadOpenVpnIpInfoFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    loadVpnDataSuccess(state, action) {
      state.vpnData = action.payload
      state.error = null
    },
    loadVpnDataFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    loadFarmStatusesSuccess(state, action) {
      state.farmStatuses = action.payload
    },
    loadFarmStatusesError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    loadEidTypesSuccess(state, action) {
      state.farmEidTypes = action.payload
    },
    loadEidTypesError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    loadFarmIssuesSuccess(state, action) {
      state.farmIssues = action.payload
    },
    loadFarmIssuesError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    loadFarmsAsSelectSuccess(state, action) {
      state.farmsAsSelect = action.payload
    },
    loadFarmsAsSelectError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setFarmStatus(state, action) {
      const splitStr = action.payload.split('|')
      const farmId = splitStr[0]
      const value = splitStr[1]

      if (state.farms && state.farms) {
        state.farms[farmId].farm.status = value
      }
    },
    setFarmEidType(state, action) {
      const splitStr = action.payload.split('|')
      const farmId = splitStr[0]
      const value = splitStr[1]

      if (state.farms) {
        state.farms[farmId].farm.eidType = value
      }
    },
    setFarmIssues(state, action) {
      const { farmId, issues } = action.payload

      if (state.farms && state.farms[farmId]) {
        state.farms[farmId].farm.issues = issues
      }
    },
    setFarmNetworkHistory(state: FarmDataState, action: PayloadAction<any>) {
      state.farmNetworkStatusHistory = action.payload
    },
    fetchFarmNetworkHistoryFailed(state: FarmDataState, action: PayloadAction<any>) {
      state.error = action.payload
    },
    deleteCameraFromStore(state: FarmDataState, action: PayloadAction<string>) {
      const splitStr = action.payload.split('|')
      const farmId = splitStr[0]
      const cameraId = splitStr[1]
      if (state.farms && state.farms[farmId]) {
        const oldCameras = state.farms[farmId].farm.cameras
        const newCameras = oldCameras?.filter((camera) => camera.cameraId !== cameraId)
        state.farms[farmId].farm.cameras = newCameras
      }
    },
    addNoteSuccess(state, action) {
      const { farmId, note } = action.payload
      if (state.farms && state.farms[farmId]) {
        state.farms[farmId].farm.notes.push(note)
      }
    },
    deleteNoteSuccess(state, action) {
      const { farmId, noteId } = action.payload
      if (state.farms && state.farms[farmId]) {
        const oldNotes = state.farms[farmId].farm.notes
        const newNotes = oldNotes?.filter((note) => note._id !== noteId)
        state.farms[farmId].farm.notes = newNotes
      }
    },
    setCamIpIsLoading(state: FarmDataState, action: PayloadAction<boolean>) {
      const isLoading = action.payload
      state.cameraIpLoading = isLoading
    },
    setFarmIpIsLoading(state: FarmDataState, action: PayloadAction<boolean>) {
      const isLoading = action.payload
      state.farmIpLoading = isLoading
    },
    setTrimLenIsLoading(state: FarmDataState, action: PayloadAction<boolean>) {
      const isLoading = action.payload
      state.trimLenLoading = isLoading
    }
  }
})

export const {
  deleteCameraFromStore,
  fetchFarmNetworkHistoryFailed,
  loadFarmDataFailed,
  loadFarmDataSuccess,
  loadOpenVpnIpInfoSuccess,
  loadOpenVpnIpInfoFailed,
  loadVpnDataSuccess,
  loadVpnDataFailed,
  loadFarmStatusesError,
  loadFarmStatusesSuccess,
  loadEidTypesError,
  loadEidTypesSuccess,
  loadFarmIssuesError,
  loadFarmIssuesSuccess,
  loadFarmsAsSelectSuccess,
  loadFarmsAsSelectError,
  setFarmEidType,
  setFarmIssues,
  setFarmStatus,
  setFarmNetworkHistory,
  addNoteSuccess,
  deleteNoteSuccess,
  setCamIpIsLoading,
  setFarmIpIsLoading,
  setTrimLenIsLoading,
} = farmDataSlice.actions
export default farmDataSlice.reducer

export const updateFarmStatus = (farmId: string, status: string): AppThunk => async dispatch => {
  try {
    const farmStatusesResp = await api.post(API_BASE_URL + 'monitor/farmStatuses',
      {
        farmId,
        status
      }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm status: ${err.message}`)
  }
}

export const fetchFarmData = (): AppThunk => async dispatch => {
  try {
    const farmDataResp = await api.get(API_BASE_URL + 'monitor/allFarmsDailyBcsReadingsCount')
    console.log('farmDataResp == ', farmDataResp)
    dispatch(loadFarmDataSuccess(farmDataResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading data: ${err.message}`)
    dispatch(loadFarmDataFailed(err.toString()))
  }
  try {
    let openVpnIpInfo: any
    if (process.env.NODE_ENV === 'production') {
      const openVpnIpInfoResp = await api.get(API_BASE_URL + 'monitor/openVpnFarmIpList')
      openVpnIpInfo = openVpnIpInfoResp.data
    } else {
      openVpnIpInfo = await mockOpenVpnFarmList
    }
    dispatch(loadOpenVpnIpInfoSuccess(openVpnIpInfo))
  } catch (err) {
    toastr.error('Error', `Error loading data: ${err.message}`)
    dispatch(loadOpenVpnIpInfoFailed(err.toString()))
  }
  try {
    let vpnData: any
    if (process.env.NODE_ENV === 'production') {
      const vpnDataResp = await api.get(API_BASE_URL + 'monitor/linuxVpnList')
      vpnData = vpnDataResp.data
    } else {
      vpnData = await mockLinuxVpnData
    }
    dispatch(loadVpnDataSuccess(vpnData))
  } catch (err) {
    toastr.error('Error', `Error loading data: ${err.message}`)
    dispatch(loadVpnDataFailed(err.toString()))
  }
}

export const fetchFarmsAsSelect = (): AppThunk => async dispatch => {
  try {
    const farmsAsSelectsResp = await api.get(API_BASE_URL + 'monitor/farmsAsSelectOptions')
    dispatch(loadFarmsAsSelectSuccess(farmsAsSelectsResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading farms for dropdown: ${err.message}`)
    dispatch(loadFarmsAsSelectError(err.toString()))
  }
}

export const fetchFarmNetworkStatusHistory = (farmId: string): AppThunk => async dispatch => {
  try {
    console.log('In the function')
    const farmNetworkStatusHistoryResp = await api.get(API_BASE_URL + 'monitor/farmNetworkStatusHistory/' + farmId)
    dispatch(setFarmNetworkHistory(farmNetworkStatusHistoryResp.data))
  } catch (err) {
    toastr.error('Error', `Error getting network history: ${err.message}`)
    dispatch(fetchFarmNetworkHistoryFailed(err.toString()))
  }
}

export const fetchFarmStatuses = (): AppThunk => async dispatch => {
  try {
    const farmStatusesResp = await api.get(API_BASE_URL + 'monitor/farmStatuses')
    dispatch(loadFarmStatusesSuccess(farmStatusesResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading farm statuses: ${err.message}`)
    dispatch(loadFarmStatusesError(err.toString()))
  }
}

export const fetchFarmEidTypes = (): AppThunk => async dispatch => {
  try {
    const eidTypesResp = await api.get(API_BASE_URL + 'monitor/farmEidTypes')
    dispatch(loadEidTypesSuccess(eidTypesResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading EID Types: ${err.message}`)
    dispatch(loadEidTypesError(err.toString()))
  }
}

export const deleteCamera = (farmId: string, cameraId: string): AppThunk => async dispatch => {
  try {
    const deleteCameraResp = await api.delete(API_BASE_URL + `farms/deleteCamera`, {
      data: {
        farmId,
        cameraId,
      }
    })
    dispatch(deleteCameraFromStore(`${farmId}|${cameraId}`))
  } catch (err) {
    // @ts-ignore
    toastr.error('Error', `Error setting farm mobility threshold one: ${err.message}`)
  }
}

export const updateFarmIssues = (farmId: string, issues: Array<string>): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/farmIssues', {
      farmId,
      issues
    }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm Issues: ${err.message}`)
  }
}

export const updateFarmIp = (farmId: string, ip: string): AppThunk => async dispatch => {
  dispatch(setFarmIpIsLoading(true))
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/farmIP', {
      farmId,
      ip
    }
    )
    dispatch(setFarmIpIsLoading(false))
  } catch (err) {
    toastr.error('Error', `Error setting farm IP: ${err.message}`)
  }
}

export const updateFarmMobilityThresholds = (farmId: string, mobilityThresholds: MobilityThresholds): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/mobilityThresholds', {
      farmId,
      mobilityThresholds
    }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm mobility thresholds: ${err.message}`)
  }
}

export const updateFarmMobilityThresholdOne = (farmId: string, mobilityThresholdOne: number): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/mobilityThresholdOne', {
      farmId,
      mobilityThresholdOne
    }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm mobility threshold one: ${err.message}`)
  }
}

export const updateFarmMobilityThresholdTwo = (farmId: string, mobilityThresholdTwo: number): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/mobilityThresholdTwo', {
      farmId,
      mobilityThresholdTwo
    }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm mobility threshold one: ${err.message}`)
  }
}

export const updateFarmMobilityThresholdThree = (farmId: string, mobilityThresholdThree: number): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + '/monitor/mobilityThresholdThree', {
      farmId,
      mobilityThresholdThree
    }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm mobility threshold one: ${err.message}`)
  }
}

export const updateTrimLen = (farmId: string, trimLen: number): AppThunk => async dispatch => {
  dispatch(setTrimLenIsLoading(true))
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/trimLen', {
      farmId,
      trimLen
    }
    )
    dispatch(setTrimLenIsLoading(false))
  } catch (err) {
    toastr.error('Error', `Error setting farm mobility threshold one: ${err.message}`)
  }
}


export const updateCameraIp = (cameraId: string, farmId: string, ip: string): AppThunk => async dispatch => {
  dispatch(setCamIpIsLoading(true))
  try {
    // const farmIssuesResp = await api.post('https://api.herd.vision/api/v1/monitor/cameraIP', {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/cameraIP', {
      cameraId,
      farmId,
      ip
    }
    )
    dispatch(setCamIpIsLoading(false))
  } catch (err) {
    toastr.error('Error', `Error setting camera IP: ${err.message}`)
  }
}

export const updateFarmEidType = (farmId: string, eidType: string): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.post(API_BASE_URL + 'monitor/farmEidType', {
      farmId,
      eidType
    }
    )
  } catch (err) {
    toastr.error('Error', `Error setting farm status: ${err.message}`)
  }
}

export const fetchFarmIssues = (): AppThunk => async dispatch => {
  try {
    const farmIssuesResp = await api.get(API_BASE_URL + 'monitor/farmIssues')
    dispatch(loadFarmIssuesSuccess(farmIssuesResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading farm issues: ${err.message}`)
    dispatch(loadFarmIssuesError(err.toString()))
  }
}

export const addNote = (farmId: string, text: string): AppThunk => async dispatch => {
  try {
    const access = localStorage.getItem(ACCESS_TOKEN) ? localStorage.getItem(ACCESS_TOKEN) : null
    const decoded: any = access ? JwtDecode(access) : null

    const addNoteResp = await api.post(API_BASE_URL + 'monitor/farmNotes', {
      farmId,
      text,
      timestamp: moment().toISOString(),
      username: decoded ? decoded.username : ''
    })
    dispatch(addNoteSuccess({
      farmId: farmId,
      note: addNoteResp.data
    }))
  } catch (err) {
    toastr.error('Error', `Error adding note to farm: ${err.message}`)
  }
}

export const deleteNote = (farmId: string, noteId: string): AppThunk => async dispatch => {
  try {
    const deleteNoteRest = await api.delete(API_BASE_URL + 'monitor/farmNotes', {
      data: {
        farmId,
        noteId,
      },
    })
    dispatch(deleteNoteSuccess({
      farmId: farmId,
      noteId: noteId
    }))
  } catch (err) {
    toastr.error('Error', `Error deleting note on a farm: ${err.message}`)
  }
}