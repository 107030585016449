/* @flow */

import * as React from 'react'
// @ts-ignore
import css from './login.module.css'
// import NavBar from '../../../../src/js/App/Layout/Nav'

// import BootstrapNavStyling from '../../App/Layout/Nav'
// const css = require('@herd-vision/client/src/js/components/Login/Login')
// const css = require('./login_component')

// const ArrowIcon = require('@herd-vision/ui-components/src/images/arrow.svg')
// const HerdVisionIcon = require('@herd-vision/ui-components/src/images/herdvision_primary.svg')
// const CowImageBackground = require('@herd-vision/ui-components/src/images/CowHerdBackgroundImage.jpg')

type Props = {
  onLoginClick: Function
  errorMessage: string
  display: boolean
}

export default class Login extends React.Component<Props> {
  username: any | undefined
  password: any | undefined

  render() {
    const { errorMessage } = this.props

    return (
      <div className={css.container}>
        <div className={css.subContainer}>
          {/* <img
            src={HerdVisionIcon}
            alt="Main herdvision logo"
            className={css.herdVisionIcon}
          /> */}
          {/*
          <p className={css.paragraphText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elite, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          */}

          <form onSubmit={this.handleClick}>
            <div className={css.inputContainer}>
              <div className={css.labels} key="username">
                <label htmlFor="username" className={css.labelText} key="l">
                  Username/Email Address
                </label>
                <br />
                <input
                  id="username"
                  className={css.inputBoxes}
                  key="i"
                  type="text"
                  ref={username => (this.username = username)}
                  placeholder="username/email"
                />
              </div>

              <div className={css.labels} key="password">
                <label htmlFor="username" className={css.labelText} key="l">
                  Password
                </label>
                <br />
                <input
                  className={css.inputBoxes}
                  key="i"
                  type="password"
                  ref={password => (this.password = password)}
                  placeholder="password"
                />
              </div>
            </div>

            <div
              key="submit"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <button className={css.button} type="submit">
                <div className={css.buttonContent} style={{ cursor: 'pointer' }}>
                  Sign in
                  {/* <img src={ArrowIcon} className={css.arrowIcon} /> */}
                </div>
              </button>
            </div>

            <div key="space" className="clearfix" />
            {errorMessage && (
              <p
                key="errors"
                className="text-center"
                style={{ color: 'red', paddingTop: '12px' }}
              >
                {errorMessage}
              </p>
            )}
          </form>
        </div>
      </div>
    )
  }
  handleClick = (event: any) => {
    event.preventDefault()
    const creds = {
      username: this.username ? this.username.value.trim() : '',
      password: this.password ? this.password.value.trim() : ''
    }
    this.props.onLoginClick(creds)
  }
}
