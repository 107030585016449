import React from "react"
import moment from "moment"
import uptimeCalculator, {
  UptimeGraphPart,
  areDatesValid,
} from "../utils/uptimeCalc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { ResponsiveBar } from "@nivo/bar"
import { ApiConnectedHistory, VpnConnectedHistory } from "../redux/types"

interface Props {
  vpnConnectedHistory: VpnConnectedHistory[]
  apiConnectedHistory: ApiConnectedHistory[]
  dateFromChange: (dateFrom: Date) => void
  dateToChange: (dateTo: Date) => void
}

interface TooltipProps {
  id: number | string
  value: string | number
  isZero: boolean
  format: string
}

const Tooltip = (props: TooltipProps) => {
  return (
    <div className="tooltip">
      <div className="value">{props.isZero ? 0 : props.value}%</div>
      <div className="value">{moment.utc(props.id).format(props.format)}</div>
    </div>
  )
}

const HOUR_FORMAT = "DD/MM/YYYY h:mm a"
const DAY_FORMAT = "DD/MM/YYYY"

const UptimeGraph = (props: Props) => {
  const {
    vpnConnectedHistory,
    apiConnectedHistory,
    dateFromChange,
    dateToChange,
  } = props

  let oneMonthAgo = moment().subtract(1, "month")
  let today = moment()

  const [vpnData, setVpnData] = React.useState<UptimeGraphPart[]>()
  const [apiData, setApiData] = React.useState<UptimeGraphPart[]>()
  const [dateFrom, setDateFrom] = React.useState(oneMonthAgo.toDate())
  const [dateTo, setDateTo] = React.useState(today.toDate())
  const [groupBy, setGroupBy] = React.useState<"day" | "hour">("day")

  React.useEffect(() => {
    if (
      vpnConnectedHistory &&
      apiConnectedHistory &&
      dateFrom &&
      dateTo &&
      groupBy
    ) {
      let errorMessage = areDatesValid(dateFrom, dateTo, groupBy)
      if (errorMessage) {
        alert(errorMessage)
      } else {
        let vpnData = uptimeCalculator(
          vpnConnectedHistory,
          dateFrom,
          dateTo,
          groupBy
        )
        let apiData = uptimeCalculator(
          apiConnectedHistory,
          dateFrom,
          dateTo,
          groupBy
        )
        setVpnData(vpnData)
        setApiData(apiData)
      }
    }
  }, [vpnConnectedHistory, dateFrom, dateTo, groupBy])

  return (
    <>
      <div className="uptime">
        <div className="filters">
          <div className="date-from">
            <p>Date From:</p>
            <input
              type="date"
              min={"2024-01-01"}
              max={today.format("YYYY-MM-DD")}
              value={moment(dateFrom).format("YYYY-MM-DD")}
              onChange={(e) => {
                if (e.target.value) {
                  dateFromChange(new Date(e.target.value))
                  setDateFrom(new Date(e.target.value))
                }
              }}
            />
          </div>
          <div className="date-to">
            <p>Date To:</p>
            <input
              type="date"
              min={"2024-01-01"}
              max={today.format("YYYY-MM-DD")}
              value={moment.utc(dateTo).format("YYYY-MM-DD")}
              onChange={(e) => {
                if (e.target.value) {
                  dateToChange(new Date(e.target.value))
                  setDateTo(new Date(e.target.value))
                }
              }}
            />
          </div>
          <div className="group-by">
            <p>Group By:</p>
            <select
              value={groupBy}
              onChange={(e) => {
                if (e.target.value === "day" || e.target.value === "hour") {
                  setGroupBy(e.target.value)
                }
              }}
            >
              <option value="day">Day</option>
              <option value="hour">Hour</option>
            </select>
          </div>
          <div>
            <p>Presets:</p>
            <div
              className="preset-button"
              onClick={() => {
                setGroupBy("hour")
                setDateFrom(moment.utc().subtract(24, "hours").toDate())
                setDateTo(today.toDate())
              }}
            >
              Each hour today
            </div>
          </div>
          <div>
            <div
              className="preset-button"
              onClick={() => {
                setGroupBy("hour")
                setDateFrom(moment.utc().subtract(3, "d").toDate())
                setDateTo(today.toDate())
              }}
            >
              Each hour last 3 days
            </div>
          </div>
          <div>
            <div
              className="preset-button"
              onClick={() => {
                setGroupBy("day")
                setDateFrom(moment.utc().subtract(7, "d").toDate())
                setDateTo(today.toDate())
              }}
            >
              Each day last 7 days
            </div>
          </div>
          <div>
            <div
              className="preset-button"
              onClick={() => {
                setGroupBy("day")
                setDateFrom(moment.utc().subtract(1, "months").toDate())
                setDateTo(today.toDate())
              }}
            >
              Each day last month
            </div>
          </div>
          <FontAwesomeIcon
            className="reset-dates"
            icon={faSyncAlt}
            title="Reset Dates"
            onClick={() => {
              setDateFrom(oneMonthAgo.toDate())
              setDateTo(today.toDate())
              setGroupBy("day")
            }}
          />
        </div>
        {vpnData && (
          <div
            style={{
              height: "500px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ResponsiveBar
              data={vpnData}
              indexBy={"timestamp"}
              keys={["uptime", "color", "isZero"]}
              colors={(v) => v.data.color}
              margin={{ top: 50, right: 110, bottom: 100, left: 80 }}
              animate={true}
              enableGridY={true}
              enableLabel={false}
              minValue={0}
              maxValue={100}
              tooltip={(input) => (
                <Tooltip
                  id={input.data.timestamp}
                  value={input.value}
                  isZero={!!input.data.isZero}
                  format={groupBy === "day" ? DAY_FORMAT : HOUR_FORMAT}
                />
              )}
              axisBottom={{
                format: (v) => {
                  if (groupBy === "hour") {
                    return moment.utc(v).format("ha")
                  }
                  return moment(v).day() === 1
                    ? moment
                        .utc(v)
                        .format(groupBy === "day" ? DAY_FORMAT : "ha")
                    : ""
                },
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "Date",
                legendPosition: "middle",
                legendOffset: 80,
              }}
              axisLeft={{
                format: (v) => v + "%",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "VPN Uptime",
                legendPosition: "middle",
                legendOffset: -60,
                tickValues: [0, 20, 40, 60, 80, 100],
              }}
            />
          </div>
        )}
        {apiData && (
          <div
            style={{
              height: "500px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ResponsiveBar
              data={apiData}
              indexBy={"timestamp"}
              keys={["uptime", "color", "isZero"]}
              colors={(v) => v.data.color}
              margin={{ top: 50, right: 110, bottom: 100, left: 80 }}
              animate={true}
              enableGridY={true}
              enableLabel={false}
              minValue={0}
              maxValue={100}
              tooltip={(input) => (
                <Tooltip
                  id={input.data.timestamp}
                  value={input.value}
                  isZero={!!input.data.isZero}
                  format={groupBy === "day" ? DAY_FORMAT : HOUR_FORMAT}
                />
              )}
              axisBottom={{
                format: (v) => {
                  if (groupBy === "hour") {
                    return moment.utc(v).format("ha")
                  }
                  return moment(v).day() === 1
                    ? moment
                        .utc(v)
                        .format(groupBy === "day" ? DAY_FORMAT : "ha")
                    : ""
                },
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "Date",
                legendPosition: "middle",
                legendOffset: 80,
              }}
              axisLeft={{
                format: (v) => v + "%",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "API Uptime",
                legendPosition: "middle",
                legendOffset: -60,
                tickValues: [0, 20, 40, 60, 80, 100],
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default UptimeGraph
