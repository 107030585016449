import { VpnConnected, VpnData } from "../redux/types"

const ROUTER_IP_CHAR_START = 6
const ROUTER_IP_CHAR_END = 9
const ROUTER_IP_VALUE = '100'

export const sortVpnData = (vpnData: VpnData, col: string, dir: string): VpnConnected[] => {
  if (!vpnData || !vpnData.connected || !(dir === 'asc' ||  dir === 'desc')) {
    return vpnData.connected
  }

  let connections: VpnConnected[] = []
  let routerConnections: VpnConnected[] = []

  Object.entries(vpnData.connected).map(([key, con]: [any, VpnConnected]) => {
    if (con.ip.slice(ROUTER_IP_CHAR_START, ROUTER_IP_CHAR_END) == ROUTER_IP_VALUE) {
      routerConnections.push(con)
    } else {
      connections.push(con)
    }
  })

  if (['ip'].includes(col)) {
    connections.sort((a, b) => parseInt(a.ip.replace(/[.]/g, '')) - parseInt(b.ip.replace(/[.]/g, '')))
    routerConnections.sort((a, b) => parseInt(a.ip.replace(/[.]/g, '')) - parseInt(b.ip.replace(/[.]/g, '')))
  }

  if (dir === 'desc') {
    connections.reverse()
    routerConnections.reverse()
  }

  connections.push(...routerConnections)

  return connections
}