import { reducer as toastr } from 'react-redux-toastr'
import { combineReducers } from 'redux'
import auth from './reducers/authReducer'
import configDataReducer from './slices/config_slice'
import farmDataReducer from './slices/farm_data_slice'
import statusReducer from './slices/status_slice'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const rootReducer = combineReducers({
  configDataReducer,
  farmDataReducer,
  statusReducer,
  toastr,
  auth,
})

const reducersToPersist: string[] = [
  'auth',
  'configDataReducer'
]

const persistConfig = {
  key: 'root',
  storage,
  whitelist: reducersToPersist,
}

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer