import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  REFRESH_REQUEST,
  REFRESH_SUCCESS,
  REFRESH_FAILURE,
  REFRESH_TOKEN,
} from '../actions/authActions'

export default function reducer(
  state = {
    token: null,
    username: null,
    userId: null,
    groups: null,
    isFetching: false,
    isAuthenticated: !!localStorage.getItem(REFRESH_TOKEN),
  },
  action: any
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }
    case LOGIN_SUCCESS:
      console.log(action.payload.decoded)
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: action.payload.access,
        username: action.payload.decoded.username,
        userId: action.payload.decoded.userId,
        groups: action.payload.decoded.groups,
        errorMessage: '',
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        username: null,
        userId: null,
        groups: null,
        errorMessage: action.payload.message,
      }
    case REFRESH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }
    case REFRESH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: action.payload.access,
        errorMessage: '',
      }
    case REFRESH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.payload.message,
      }
    case LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        username: null,
        userId: null,
        groups: null,
      }
    default:
      return state
  }
}
