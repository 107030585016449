import React, { memo } from 'react'
import Select, { ActionMeta, OptionTypeBase, ValueType } from 'react-select'
import '../../styles/farms_overview.css'
import { ConfigState } from '../redux/slices/config_slice'
import { FarmData, FarmEidTypes, FarmStatuses } from '../redux/types'

interface Props {
  farmData: { [key: string]: FarmData }
  farmStatuses: FarmStatuses
  farmEidTypes: FarmEidTypes
  farmIssues: any
  setFarmStatus: Function
  submitFarmIp: (farmId: string, ip: string) => void
  // Function type from react-select types
  updateFarmIssues: (value: ValueType<OptionTypeBase>, action: ActionMeta) => void
  configData: ConfigState | undefined
  setFarmEidType: Function
}

function InstallsOverview(props: Props) {
  const { farmEidTypes, farmData, farmIssues, farmStatuses } = props

  const errorArray: Array<FarmData> = []
  const nonErrorArray: Array<FarmData> = []
  let sortedFarmData: Array<FarmData> = []

  // console.log('farmData == ', farmData)
  if (farmData !== {}) {
    Object.entries(farmData).map(([key, fd]: [any, FarmData]) => {
      if (fd.farm.issues?.includes('noeid') || (fd.farm.status !== 'cameraAndEidConnected' && fd.farm.status !== 'installedWithIssue')) {
        sortedFarmData.push(fd)
      }
    })
  }

  // sortedFarmData = [...errorArray, ...nonErrorArray]

  return (
    <div>
      <table id="farms" className={`${props.configData?.darkMode ? 'dark' : ''}`}>
        <thead>
          <tr>
            <th>Farm Name</th>
            {props.configData?.showHidden &&
              <th>Farm ID</th>
            }
            {props.configData?.showHidden &&
              <th>IP</th>
            }
            {props.configData?.showHidden &&
              <th>EID Type</th>
            }
            <th>Cow Count</th>
            <th>Install Status</th>
            <th>Issues</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(sortedFarmData).map(([key, fd]: [any, FarmData]) => {
            let reactSelectOptions = []
            if (farmIssues.selectOptions && fd.farm.issues) {
              reactSelectOptions = farmIssues.selectOptions.filter((issue: any) => {
                return fd.farm.issues?.includes(issue.value)
              })
            }

            return (
              <tr key={fd.farm._id}>
                <td>{fd.farm.name}</td>
                {props.configData?.showHidden &&
                  <td>{fd.farm._id}</td>
                }
                {props.configData?.showHidden &&
                  <td><input type="text" defaultValue={fd.farm.ip} onChange={(e) => props.submitFarmIp(fd.farm._id, e.target.value)} /></td>
                }
                {props.configData?.showHidden &&
                  <td>
                    <select onChange={(e) => props.setFarmEidType(e.target.value)}>
                      {farmEidTypes &&
                        farmEidTypes.selectOptions.map((eidType: any) => {
                          return <option key={eidType.value} value={fd.farm._id + '|' + eidType.value} selected={fd.farm.eidType === eidType.value}>{eidType.label}</option>
                        }
                        )}
                      {!farmEidTypes &&
                        <option>Loading....</option>
                      }
                    </select>
                  </td>
                }
                <td>{fd.cowCount}</td>
                <td>
                  <select onChange={(e) => props.setFarmStatus(e.target.value)}>
                    {farmStatuses &&
                      farmStatuses.selectOptions.map((status: any) => {
                        return <option key={status.value} value={fd.farm._id + '|' + status.value} selected={fd.farm.status === status.value}>{status.label}</option>
                      }
                      )}
                    {!farmStatuses &&
                      <option>Loading....</option>
                    }
                  </select>
                </td>
                <td>
                  {farmIssues &&
                    <Select
                      name={fd.farm._id}
                      isMulti
                      onChange={props.updateFarmIssues}
                      options={farmIssues.selectOptions}
                      value={reactSelectOptions}
                    />
                  }
                  {!farmIssues &&
                    <div>Loading....</div>
                  }
                </td>
              </tr>
            )
          }
          )}
        </tbody>
      </table>
    </div>
  )
}

export default memo(InstallsOverview)