import { FarmFlag } from '../redux/types'

export const getFlagByName = (flagName: string, flags: Array<FarmFlag>): any => {
  const ret = flags.find((flag) => flag.hasOwnProperty(flagName))

  if (ret === undefined) {
    return undefined
  }
  // @ts-ignore
  return ret[flagName]
}