import React, { memo } from 'react'
import { ConfigState } from '../redux/slices/config_slice'
import { OpenVpnIpInfo, VpnConnected, VpnData } from "../redux/types"
import { sortVpnData } from "../utils/sortVpnData"
import SortColIcon from "./sort_col_icon"

interface Props {
  openVpnIpInfo: OpenVpnIpInfo
  vpnData: VpnData
  configData: ConfigState | undefined
}

function IpList(props: Props) {
  const { openVpnIpInfo, vpnData } = props

  const [sortColumn, setSortColumn] = React.useState("")
  const [sortDirection, setSortDirection] = React.useState("")
  const [connections, setConnections] = React.useState<VpnConnected[]>()

  const handleColumnClick = (column: string) => {
    let newSortDir: string
    switch (sortDirection) {
      case "":
        newSortDir = "asc"
        break
      case "asc":
        newSortDir = "desc"
        break
      case "desc":
        newSortDir = ""
        break
      default:
        newSortDir = ""
        break
    }

    // When a column is clicked for the first we sort it by ascending
    if (column !== sortColumn) {
      newSortDir = "asc"
    }

    setSortColumn(column)
    setSortDirection(newSortDir)
  }

  React.useEffect(() => {
    if (vpnData) {
      setConnections(vpnData.connected)
    }
  }, [vpnData])

  React.useEffect(() => {
    if (vpnData && sortColumn && sortDirection) {
      setConnections(sortVpnData(vpnData, sortColumn, sortDirection))
    }
  }, [vpnData, sortColumn, sortDirection])

  return (
    <React.Fragment>
      <div style={{
        backgroundColor: props.configData?.darkMode ? '#0f0f0fa4' : 'white',
        color: props.configData?.darkMode ? 'white' : 'black'
      }}>
        <h3>Last {openVpnIpInfo.lastUpdated}</h3>
        <h2>Linux VPN</h2>

        <h3 style={{ color: "#4CAF50" }}>Connected</h3>
        <table id="farms" className={`${props.configData?.darkMode ? 'dark' : ''}`}>
          <thead>
            <tr>
              <th>Name</th>
              <th style={{ padding: "8px 0px 8px 0px" }}>
                <div className="thead-title">IP</div>
                <SortColIcon
                  colDir={sortColumn === "ip" ? sortDirection : ""}
                  handleClick={() => handleColumnClick("ip")}
                />
              </th>
              <th>Latest Handshake</th>
              <th>Transfer</th>
            </tr>
          </thead>
          <tbody>
            {connections?.map((data) => {
              const transfer = `Sent: ${data.transfer.sent} ${data.transfer.sentUnit}, Received: ${data.transfer.received} ${data.transfer.receivedUnit}`
              return (
                <tr>
                  <td>{data.name}</td>
                  <td>{data.ip}</td>
                  <td>{data.latestHandshake}</td>
                  <td>{transfer}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <h3 style={{ color: "#ff0000" }}>Not Connected</h3>
        <table id="vpnDataConnected">
          <thead>
            <tr>
              <th>Name</th>
              <th>IP</th>
            </tr>
          </thead>
          <tbody>
            {vpnData.notConnected.map((data) => {
              console.log("data == ", data)
              return (
                <tr>
                  <td>{data.name}</td>
                  <td>{data.ip}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {/* <h2>Old VPN Client List</h2>
      <table id="openVpnClientList">
        <thead>
          <tr>
            {props.openVpnIpInfo.json.clientList.headers.map((headerItem) => {
              return <th>{headerItem}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {props.openVpnIpInfo.json.clientList.data.map((dataArray) => {
            return <tr>
              {dataArray.map((dataItem) => {
                return <td>{dataItem}</td>
              })}
            </tr>
          })}
        </tbody>
      </table> */}
        <h2>Routing Table</h2>
        <table id="farms" className={`${props.configData?.darkMode ? 'dark' : ''}`}>
          <thead>
            <tr>
              {openVpnIpInfo.json.routingTable.headers.map((headerItem) => {
                return <th>{headerItem}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {openVpnIpInfo.json.routingTable.data.map((dataArray) => {
              return (
                <tr>
                  {dataArray.map((dataItem) => {
                    return <td>{dataItem}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default memo(IpList)