import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, PingData } from '../types'
import { toastr } from 'react-redux-toastr'
// import { DiskUsage } from 'diskusage'

type DiskUsage = {
  available: number;
  free: number;
  total: number;
}

import api, { API_BASE_URL } from '../actions/api'
// import api from 'actions/api'

export interface StatusState {
  pings: Array<PingData>
  hdd: DiskUsage
  error: string | null
}

const initialState: StatusState = {
  pings: [],
  hdd: {
    available: 0,
    free: 0,
    total: 0
  },
  error: null
}

const statusSlice = createSlice({
  name: 'status',
  initialState: initialState,
  reducers: {
    getHddSuccess(state, action) {
      state.hdd = action.payload
      state.error = null
    },
    getHddFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    getPingsSuccess(state, action) {
      state.pings = action.payload
      state.error = null
    },
    getPingsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  }
})

export const {
  getHddFailed,
  getHddSuccess,
  getPingsSuccess,
  getPingsFailed
} = statusSlice.actions
export default statusSlice.reducer

export const getPings = (farmId: string, dateFrom: Date, dateTo: Date): AppThunk => async dispatch => {
  try {
    const pingsResp = await api.get(API_BASE_URL + `monitor/pings/${farmId}?date_from=${dateFrom}&date_to=${dateTo}`)
    // const pings = await api.get(`https://api.herd.vision/api/v1/status/pings/${farmId}`)
    // const pings = await pingsResp.json()
    dispatch(getPingsSuccess(pingsResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading pings: ${err.message}`)
    dispatch(getPingsFailed(err.toString()))
  }
}

export const getHdd = (): AppThunk => async dispatch => {
  try {
    const hddResp = await api.get(API_BASE_URL + `monitor/hdd`)
    // const hdd = await hddResp.json()
    dispatch(getHddSuccess(hddResp.data))
  } catch (err) {
    toastr.error('Error', `Error loading HDD data: ${err.message}`)
    dispatch(getHddFailed(err.toString()))
  }
}
